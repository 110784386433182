/* Loader */
.loader_text {
  font-family: 'Gotham';
  width: 50vw;
  font-size: 10vw;
  font-weight: 900;
  text-align: center;
  color: var(--primary-red);
}

.loader_img_cont {
  width: 50vw;
  height: 30vw;
  display: flex;
  align-items: end;
  margin: -1vw -1vw 0 0;
}

.loader_img {
  width: 50vw;
  height: 0vw;
  background: url('/public/images/Pepsodent_vector_main.svg') no-repeat;
  background-position: center bottom;
  background-size: 40vw;
}

.portrait_instruction {
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  padding-top: 30vh;
}

.portrait_instruction img {
  width: 40vw;
}

.portrait_instruction div {
  text-transform: uppercase;
  color: var(--primary-blue);
  font-size: 6vw;
  font-weight: 500;
  margin-top: 4vw;
  text-align: center;
}

.btn_weight {
  font-size: 2vw;
  padding: 1vw 4.5vw;
  margin-top: 1vw;
}

.pointer_main {
  width: 4vw;
  height: 4vw;
  background: #002172bb;
  border: solid 0.2vw #fff;
  border-radius: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.pointer_inner {
  width: 2vw;
  height: 2vw;
  background: #fff;
  border-radius: 100vw;
}

.pointer_main_weight {
  width: 1.5vw;
  height: 1.5vw;
}

.pointer_inner_weight {
  width: 0.8vw;
  height: 0.8vw;
}

.pointer_msg {
  position: absolute;
  bottom: 5vw;
  left: -6vw;
  width: 15vw;
  color: #fff;
  background: #002172bb;
  padding: 1vw;
  font-family: 'Gotham';
  font-size: 1.8vw;
  line-height: 2vw;
}

.brush_progress {
  width: 100%;
  height: 1vw;
  border-radius: 100vw;
  margin-top: 1vw;
  border: solid 0.11vw #fff;
}

.brush_progress>div {
  width: 0%;
  height: 100%;
  border-radius: 100vw;
  background: var(--primary-red);
}

.cursor_pointer {
  width: 2.5vw;
  transform: rotateX(180deg) rotateZ(160deg);
}

.reset_btn {
  font-size: 2vw;
  padding: 1.5vw 3vw;
  position: absolute;
  left: 2vw;
  bottom: 2vw;
  z-index: 9;
}

/* Tablet styling */
@media only screen and (min-width: 600px) {

  .loader_text {
    width: 30vw;
    font-size: 4vw;
  }

  .loader_img_cont {
    width: 30vw;
    height: 8vw;
    margin: -1vw -1vw 0 0;
  }

  .loader_img {
    width: 30vw;
    background-size: 20vw;
  }

  .reset_btn {
    font-size: 2vw;
    padding: 1vw 3vw;
  }

  .pointer_msg {
    bottom: 4vw;
    left: -10vw;
    width: 20vw;
    font-size: 2vw;
    line-height: 2.2vw;
  }


  .text_final_counter {
    color: white;
    text-align: center;
    font-Size: 3vw;
  }

  .text_final_counter>div {
    font-size: 1.5vw;
  }

  .text_final_days {
    color: white;
    background: #002172dd;
    padding: 0 0 2vw 0;
    font-size: 6vw;
    width: 15vw;
    text-align: center;
  }

  .text_final_days>div {
    font-size: 2vw;
  }
  
  .qlf_title {
    color: white;
    width: 60vw;
    font-size: 2.5vw;
  }

}

/* Desktop styling */
@media only screen and (min-width: 1200px) {

  .loader_text {
    width: 30vw;
    font-size: 3vw;
  }

  .loader_img_cont {
    width: 30vw;
    height: 8vw;
    margin: -1vw -1vw 0 0;
  }

  .loader_img {
    width: 30vw;
    background-size: 20vw;
  }

  .btn_weight {
    font-size: 0.8vw;
    padding: 0.5vw 3.8vw;
    margin-top: 0.5vw;
  }

  .pointer_main {
    width: 2vw;
    height: 2vw;
    background: #002172bb;
    border: solid 0.15vw #fff;
    border-radius: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pointer_inner {
    width: 1vw;
    height: 1vw;
    background: #fff;
    border-radius: 100vw;
  }

  .pointer_main_weight {
    width: 1.5vw;
    height: 1.5vw;
  }

  .pointer_inner_weight {
    width: 0.8vw;
    height: 0.8vw;
  }

  .pointer_msg {
    bottom: 4vw;
    left: -2vw;
    width: 12vw;
    font-size: 1.2vw;
    line-height: 1.4vw;
  }

  .brush_progress {
    width: 100%;
    height: 0.5vw;
    border-radius: 100vw;
    margin-top: 0.5vw;
    border: solid 0.11vw #fff;
  }

  .brush_progress>div {
    width: 0%;
    height: 100%;
    border-radius: 100vw;
    background: var(--primary-red);
  }

  .reset_btn {
    font-size: 1vw;
    padding: 0.8vw 2vw;
  }



  .text_final_counter {
    color: white;
    text-align: center;
    font-Size: 2vw;
  }

  .text_final_counter>div {
    font-size: 1vw;
  }

  .text_final_days {
    color: white;
    background: #002172dd;
    padding: 0 0 1vw 0;
    font-size: 4vw;
    width: 10vw;
    text-align: center;
  }

  .text_final_days>div {
    font-size: 1.5vw;
  }

  .qlf_title {
    color: white;
    width: 50vw;
    font-size: 2vw;
  }

}

@media (orientation: portrait) {
  .portrait_instruction {
    display: flex;
  }
}

@media (orientation: landscape) {
  .portrait_instruction {
    display: none;
  }
}