/* Splash Screen */
.splash_screen {
  font-family: 'Gotham';
  padding: 15vw 0;
  text-transform: uppercase;
}

.splash_logo {
  margin: 8vw;
  width: 40vw;
}

.splash_title {
  color: var(--primary-red);
  font-size: 24vw;
  font-weight: 900;
  line-height: 24vw;
  margin-left: 8vw;
}

.splash_subtitle {
  color: var(--primary-blue);
  font-size: 8vw;
  font-weight: 900;
  line-height: 9vw;
  margin-left: 8vw;
}

.splash_vector {
  margin-top: -20vw;
  width: 95vw;
  position: relative;
  z-index: -1;
}

.splash_paste {
  width: 100vw;
}

/* .splash_desktop {
  display: none;
} */

@media only screen and (min-width: 900px) {

  /* Splash Screen */
  .splash_screen {
    padding: 2vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-image: url('/public/images/splash_bg_v2.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    background-size: 100%;
  }

  .splash_logo {
    margin: 5vw 0 2vw 0;
    width: 15vw;
  }

  .splash_title_cont {
    display: flex;
    align-items: center;
  }

  .splash_title {
    font-size: 4vw;
    line-height: 4vw;
    margin: 1vw 0 3vw 0;
    width: 40vw;
    text-align: center;
  }

  .splash_title div {
    font-size: 3vw;
    line-height: 3vw;
  }

  .splash_subtitle {
    font-size: 2.5vw;
    line-height: 3vw;
    margin-left: 1vw;
  }

  .splash_img_cont {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-top: 8vw;
  }

  .splash_eggs {
    width: 20vw;
  }

  .splash_paste {
    width: 45vw;
  }

  /* 
  .splash_desktop {
    display: block;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
  } */
}

@media only screen and (min-width: 1200px) {
  .splash_logo {
    margin: 0 0 2vw 0;
    width: 12vw;
  }

  .splash_title {
    font-size: 3vw;
    line-height: 3vw;
    margin: 0 0 3vw 0;
    width: 40vw;
    text-align: center;
  }

  .splash_title div {
    font-size: 2vw;
    line-height: 2vw;
  }

  .splash_subtitle {
    font-size: 1.8vw;
    line-height: 2vw;
    margin-left: 1vw;
  }

  .splash_img_cont {
    margin-top: 0;
  }
}